/**
 * Graph index.ts
 * All graph configuration on here
 */

import { mxCell, mxUndoManager } from '@anekonnect/mxgraph';
import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { setGraphSetting, loadGlobalSetting, createUndoManager } from './SetSettings';
import setGraphStyle from './SetStyles';
import { GraphProps, GraphModalsProps, GraphEventsProps } from './Types';
import Events from './Events';
import HandleControl from './Control';
import Actions from './Actions';
import Modals from './Modals';
import Draw from './Draw';
import BottomAppControl from './BottomAppControl';
import EventTemplate from './EventTemplate';
import KeyboardShortcuts from './KeyboardShortcuts';
import EventDelete from './EventDelete';

import {
  wizardAddHiddenComponent,
  wizardSetShowComponentControl,
  WizardUpdateComponentCableLength,
  wizardUpdateComponentCableLength,
  WizardUpdateComponentName,
  wizardUpdateComponentName,
} from '~/store/actions/wizard/Component';
import { mx } from '~/constants/wizard';
import { AppState } from '~/store/reducers';
import { Part } from '~/api/API';
import { JSX } from 'react/jsx-runtime';
import { useAppDispatch } from '~/store/hooks';

type BaseProps = {
  container: HTMLElement | undefined;
  children: React.ReactNode;
} & GraphProps &
  GraphModalsProps &
  GraphEventsProps;

type PropsFromState = {
  paperSize: string;
  parts: Part[] | undefined;
};

type PropsFromDispatch = {
  wizardUpdateComponentName: ActionCreator<WizardUpdateComponentName>;
  wizardUpdateComponentCableLength: ActionCreator<WizardUpdateComponentCableLength>;
  wizardAddHiddenComponent: typeof wizardAddHiddenComponent;
};

type Props = BaseProps & PropsFromState & PropsFromDispatch;

const Graph = ({
  graph,
  graphRefs,
  graphForms,
  graphSetVisibles,
  container,
  children,
  componentContainerModal,
  currentCell,
  setCurrentCell,
  wizardUpdateComponentName,
  wizardUpdateComponentCableLength,
  paperSize,
  wizardAddHiddenComponent,
  parts,
}: Props) => {
  const dispatch = useAppDispatch();

  const [undoManager, setUndoManager] = useState<mxUndoManager>();

  const handleCloseComponentControl = useCallback(() => {
    dispatch(wizardSetShowComponentControl(false));
  }, [dispatch]);

  const configs = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      if (container) {
        setGraphSetting(mx, graph, paperSize);
        loadGlobalSetting(mx, graph);
        setGraphStyle(mx, graph);
        setUndoManager(createUndoManager(mx, graph));
      }
    }

    return function cleanup() {
      window.localStorage.removeItem('formCableData');
    };
  }, [graph, container, paperSize]);

  useEffect(configs, [configs]);

  const handleEditOK = useCallback(() => {
    const { componentAlias } = graphForms.editComponentForm.getFieldsValue();

    const { setModalEditVisible } = graphSetVisibles;

    if (currentCell) {
      graph.getModel().beginUpdate();

      try {
        const idData = currentCell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
        const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
        const componentType = idData.slice(0, wizardTypeIndex).join('_');
        const componentId = idData[wizardTypeIndex + 1];
        const componentSubId = idData[wizardTypeIndex + 2];
        const prefix = `${componentId}_${componentSubId}`;

        wizardUpdateComponentName(componentId, componentSubId, componentType, componentAlias);

        const parent = graph.getDefaultParent();
        const cells = graph.getChildCells(parent, true, false);

        if (componentType === 'cable') {
          const cableBezierID = `cable_ed_${componentId}_${componentSubId}_bezier_curve`;
          const bezierCurve = graph.getModel().getCell(cableBezierID);

          if (bezierCurve) {
            const value = bezierCurve.value;
            value.setAttribute('label', componentAlias);
            bezierCurve.setValue(value);
          }
        }

        cells.forEach((cell: mxCell) => {
          const component = cell.id.includes(prefix);

          if (component) {
            const value = cell.value;
            value.setAttribute('label', componentAlias);
            cell.setValue(value);
          }
        });

        currentCell.value.setAttribute('label', componentAlias);

        graph.refresh();

        const isBezier = currentCell.id.includes('bezier_curve');

        if (isBezier) {
          const {
            cableLength,
            cableTolerance,
            cableTickness,
            cableColor,
            showDimensionLine,
            cableUnit,
            toleranceUnit,
          } = graphForms.editComponentForm.getFieldsValue();

          const unitConverter = (value: number, unit: string) => {
            let convertedValue = value;
            if (value) {
              switch (unit) {
                case 'cm':
                  convertedValue = value * 10;
                  break;
                case 'meters':
                  convertedValue = value * 1000;
                  break;
                case 'in':
                  convertedValue = value * 25.4;
                  break;
                case 'ft':
                  convertedValue = value * 304.8;
                  break;
              }
            }

            return convertedValue;
          };

          const convertedCableLength = unitConverter(cableLength, cableUnit);

          if (convertedCableLength) {
            wizardUpdateComponentCableLength(
              componentId,
              componentSubId,
              componentType,
              convertedCableLength,
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const cableConfig = parts?.find((part) => part.name === 'cable');

          const bezierDimensionLineValue = `${cableLength || 3} ${cableUnit || 'mm'} +${
            cableTolerance || 0
          }/-0 ${toleranceUnit || 'mm'}`;

          const bezierDimensionLine = currentCell.id.replace('bezier_curve', 'dimension_line');
          const bezierDimensionLineCell = graph.getModel().getCell(bezierDimensionLine);

          if (bezierDimensionLineCell) {
            bezierDimensionLineCell.setValue(bezierDimensionLineValue);

            if (showDimensionLine || showDimensionLine === undefined) {
              bezierDimensionLineCell.setVisible(true);
            } else {
              bezierDimensionLineCell.setVisible(false);
            }
          }

          if (cableTickness) {
            graph.setCellStyles('strokeWidth', cableTickness, [currentCell]);
          }
          if (cableColor) {
            graph.setCellStyles('strokeColor', cableColor, [currentCell]);
          }

          const formCableData = JSON.parse(window.localStorage.getItem('formCableData') || '{}');
          formCableData[currentCell.id] = graphForms.editComponentForm.getFieldsValue();

          window.localStorage.setItem('formCableData', JSON.stringify(formCableData));
          graphForms.editComponentForm.resetFields();
        }

        graph.refresh();
      } finally {
        graph.getModel().endUpdate();
      }
    }

    // Close modal
    setModalEditVisible(false);
    handleCloseComponentControl();
  }, [
    graphForms.editComponentForm,
    graphSetVisibles,
    currentCell,
    handleCloseComponentControl,
    graph,
    wizardUpdateComponentName,
    parts,
    wizardUpdateComponentCableLength,
  ]);

  const handleEdit = useCallback(() => {
    graphSetVisibles.setModalEditVisible(true);
    handleCloseComponentControl();
  }, [graphSetVisibles, handleCloseComponentControl]);

  const handleMirror = useCallback(() => {
    graph.toggleCellStyle(mx.mxConstants.STYLE_FLIPH);
  }, [graph]);

  const handleOrderable = useCallback(
    (value: boolean) => {
      const { componentCellRef } = graphRefs.current;

      if (componentCellRef.current) {
        const container = componentCellRef.current.componentContainer;

        if (container) {
          graph.orderCells(value, [container]);
        }
      }
    },
    [graph, graphRefs],
  );

  const handleHideable = useCallback(() => {
    const { componentCellRef } = graphRefs.current;

    if (componentCellRef.current) {
      const container = componentCellRef.current.componentContainer;

      if (container) {
        container.setVisible(false);
        graph.setSelectionCells([]);
        graph.refresh();
        wizardAddHiddenComponent(container);
        handleCloseComponentControl();
      }
    }
  }, [graph, graphRefs, handleCloseComponentControl, wizardAddHiddenComponent]);

  const controlProps = {
    graph,
    graphSetVisibles,
  };

  const Control = useCallback(
    (props: JSX.IntrinsicAttributes & Pick<GraphProps, 'graphSetVisibles' | 'graph'>) => {
      return (
        <HandleControl {...props}>
          {({ deleteSelectionCell }) => {
            const actionsProps = {
              currentCell,
              graph,
              onDelete: deleteSelectionCell,
              onEdit: handleEdit,
              onMirror: handleMirror,
              onOrderFrontable: () => handleOrderable(false),
              onOrderBackable: () => handleOrderable(true),
              onHideable: handleHideable,
            };

            return <Actions {...actionsProps} />;
          }}
        </HandleControl>
      );
    },
    [graph, currentCell, handleEdit, handleMirror, handleOrderable, handleHideable],
  );

  const eventsProps = {
    graph,
    graphRefs,
    graphForms,
    graphSetVisibles,
    currentCell,
    setCurrentCell,
  };

  const modalsProps = {
    currentCell,
    componentContainerModal,
    onOk: handleEditOK,
  };

  return (
    <React.Fragment>
      <Control {...controlProps} />
      <Events {...eventsProps} />
      <EventTemplate {...eventsProps} />
      <EventDelete graph={graph} />
      <Modals {...modalsProps} />
      <Draw graph={graph} />
      {children}
      <BottomAppControl graph={graph} undoManager={undoManager} />
      <KeyboardShortcuts graph={graph} undoManager={undoManager} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    paperSize: state.assemblyWizard.paperSize,
    parts: state.data.tenantConfig.data?.parts,
  };
};

const mapDispatchToProps = {
  wizardUpdateComponentName,
  wizardUpdateComponentCableLength,
  wizardAddHiddenComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
