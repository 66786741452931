/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, ActionCreator } from 'redux';
import { mxCell } from '@anekonnect/mxgraph';

import { PartItem } from '~/types';
import { HiddenComponent } from '~/store/reducers/wizard/State';

export enum WizardComponentTypes {
  WIZARD_ADD_COMPONENT = 'WIZARD_ADD_COMPONENT',
  WIZARD_UPDATE_COMPONENT_NAME = 'WIZARD_UPDATE_COMPONENT_NAME',
  WIZARD_UPDATE_COMPONENT_CABLE_LENGTH = 'WIZARD_UPDATE_COMPONENT_CABLE_LENGTH',
  WIZARD_REMOVE_COMPONENT = 'WIZARD_REMOVE_COMPONENT',
  WIZARD_SET_COMPONENTS_DATA = 'WIZARD_SET_COMPONENTS_DATA',
  WIZARD_ADD_HIDDEN_COMPONENT = 'WIZARD_ADD_HIDDEN_COMPONENT',
  WIZARD_REMOVE_HIDDEN_COMPONENT = 'WIZARD_REMOVE_HIDDEN_COMPONENT',
  WIZARD_LOAD_HIDDEN_COMPONENTS = 'WIZARD_LOAD_HIDDEN_COMPONENTS',
  WIZARD_SET_DB_COMPONENTS_DATA = 'WIZARD_SET_DB_COMPONENTS_DATA',
  WIZARD_SET_SHOW_COMPONENT_CONTROL = 'WIZARD_SET_SHOW_COMPONENT_CONTROL',
  WIZARD_SET_SHOW_WIRE_CONTROL = 'WIZARD_SET_SHOW_WIRE_CONTROL',
}

export type WizardAddComponent = {
  item: PartItem;
  subId: number;
  alias?: string;
} & Action<WizardComponentTypes.WIZARD_ADD_COMPONENT>;

export const wizardAddComponent: ActionCreator<WizardAddComponent> = (
  item: PartItem,
  subId: number,
  alias?: string,
) => ({
  type: WizardComponentTypes.WIZARD_ADD_COMPONENT,
  item,
  subId,
  alias,
});

export type WizardUpdateComponentName = {
  id: string;
  subId: number;
  itemType: string;
  name: string;
} & Action<WizardComponentTypes.WIZARD_UPDATE_COMPONENT_NAME>;

export const wizardUpdateComponentName: ActionCreator<WizardUpdateComponentName> = (
  id: string,
  subId: number,
  itemType: string,
  name: string,
) => ({
  type: WizardComponentTypes.WIZARD_UPDATE_COMPONENT_NAME,
  id,
  subId,
  itemType,
  name,
});

export type WizardUpdateComponentCableLength = {
  id: string;
  subId: number;
  itemType: string;
  cableLength: number;
} & Action<WizardComponentTypes.WIZARD_UPDATE_COMPONENT_CABLE_LENGTH>;

export const wizardUpdateComponentCableLength: ActionCreator<WizardUpdateComponentCableLength> = (
  id: string,
  subId: number,
  itemType: string,
  cableLength: number,
) => ({
  type: WizardComponentTypes.WIZARD_UPDATE_COMPONENT_CABLE_LENGTH,
  id,
  subId,
  itemType,
  cableLength,
});

export type WizardRemoveComponent = {
  id: string;
  subId: number;
  itemType: string;
} & Action<WizardComponentTypes.WIZARD_REMOVE_COMPONENT>;

export const wizardRemoveComponent: ActionCreator<WizardRemoveComponent> = (
  id: string,
  subId: number,
  itemType: string,
) => ({
  type: WizardComponentTypes.WIZARD_REMOVE_COMPONENT,
  id,
  subId,
  itemType,
});

export type WizardSetComponentsData = {
  components: any;
} & Action<WizardComponentTypes.WIZARD_SET_COMPONENTS_DATA>;

export const wizardSetComponentsData: ActionCreator<WizardSetComponentsData> = (
  components: any,
) => ({
  type: WizardComponentTypes.WIZARD_SET_COMPONENTS_DATA,
  components,
});

export type WizardAddHiddenComponent = {
  cell: mxCell;
} & Action<WizardComponentTypes.WIZARD_ADD_HIDDEN_COMPONENT>;

export const wizardAddHiddenComponent = (cell: mxCell) => ({
  type: WizardComponentTypes.WIZARD_ADD_HIDDEN_COMPONENT,
  cell,
});

export type WizardRemoveHiddenComponent = {
  id: string;
} & Action<WizardComponentTypes.WIZARD_REMOVE_HIDDEN_COMPONENT>;

export const wizardRemoveHiddenComponent = (id: string) => ({
  type: WizardComponentTypes.WIZARD_REMOVE_HIDDEN_COMPONENT,
  id,
});

export type WizardLoadHiddenComponents = {
  hiddenComponents: HiddenComponent[];
} & Action<WizardComponentTypes.WIZARD_LOAD_HIDDEN_COMPONENTS>;

export const wizardLoadHiddenComponents = (hiddenComponents: HiddenComponent[]) => ({
  type: WizardComponentTypes.WIZARD_LOAD_HIDDEN_COMPONENTS,
  hiddenComponents,
});

export type WizardSetDBComponentsData = {
  components: any;
} & Action<WizardComponentTypes.WIZARD_SET_DB_COMPONENTS_DATA>;

export const wizardSetDBComponentsData: ActionCreator<WizardSetDBComponentsData> = (
  components: any,
) => ({
  type: WizardComponentTypes.WIZARD_SET_DB_COMPONENTS_DATA,
  components,
});

export type WizardSetShowComponentControl = {
  show: boolean;
} & Action<WizardComponentTypes.WIZARD_SET_SHOW_COMPONENT_CONTROL>;

export const wizardSetShowComponentControl: ActionCreator<WizardSetShowComponentControl> = (
  show: boolean,
) => ({
  type: WizardComponentTypes.WIZARD_SET_SHOW_COMPONENT_CONTROL,
  show,
});

export type WizardSetShowWireControl = {
  show: boolean;
} & Action<WizardComponentTypes.WIZARD_SET_SHOW_WIRE_CONTROL>;

export const wizardSetShowWireControl: ActionCreator<WizardSetShowWireControl> = (
  show: boolean,
) => ({
  type: WizardComponentTypes.WIZARD_SET_SHOW_WIRE_CONTROL,
  show,
});
